import React from "react";
import SliderBlock from "../components/SliderBlock";
import { StaticImage } from "gatsby-plugin-image";
import DefaultButton from "../components/buttons/DefaultButton";

const SliderTwo = (props) => (
  <SliderBlock
    title="Prestiti e finanziamenti a tasso fisso agevolato<br />per tutti i dipendenti pubblici, statali e ministeriali"
    bgColor="#f1f1f1"
    className="mt-5 mb-2"
    id="section-target-prodotti"
  >
    <div>
      <StaticImage
        src="../assets/images/prod-carabiniere.png"
        className="img-fluid mx-auto"
        alt="Forze armate - carabinieri"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Sei un dipendente delle Forze Armate?<br />
        Cessione del quinto per Carabinieri
      </h3>
      <p className="slick_slide__description">
        Il prestito con Cessione del Quinto per Carabinieri consente a dipendenti 
        in servizio o in pensione di richiedere un credito immediato a condizioni agevolate. 
        Richiedi subito il tuo prestito online e senza impegno!
      </p>
      <DefaultButton
        title="Scopri di più"
        url="/cessione-del-quinto-carabinieri/"
        className="mt-5 btn-outline-dark"
      />
    </div>
    <div>
      <StaticImage
        src="../assets/images/prod-medico.png"
        className="img-fluid mx-auto"
        alt="Medico"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Sei un medico o un operatore sanitario?<br />
        Cessione del Quinto per Medici Convenzionati
      </h3>
      <p className="slick_slide__description">
        Il prestito - attraverso la Cesisone del Quinto dello stipendio - è un finanziamento semplice e veloce. 
        Tasso fisso e rate costanti per il personale della salute pubblica in convenzione con il settore sanitario nazionale.
      </p>
      <DefaultButton title="Scopri di più" className="mt-5 btn-outline-dark" url="/cessione-del-quinto-per-medici-convenzionati/" />
    </div>
    <div>
      <StaticImage
        src="../assets/images/target-pensionato.png"
        className="img-fluid mx-auto"
        alt="Pensionato"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Sei un pensionato?<br />
        Prestiti in convenzione INPS per pensionati
      </h3>
      <p className="slick_slide__description">
        Con la Cessione del Quinto della pensione puoi richiedere fino a 75.000€ rimborsabili direttamente 
        dalla tua pensione e approfittare di tassi agevolati in convenzione INPS, ex INPDAP, ex ENPALS.
      </p>
      <DefaultButton
        title="Scopri di più"
        url="/prestiti-in-convenzione-inps/"
        className="mt-5 btn-outline-dark"
      />
    </div>
    <div>
      <StaticImage
        src="../assets/images/prod-insegnante.png"
        className="img-fluid mx-auto p"
        alt="Insegnante"
        placeholder="blurred"
        layout="fixed"
        width={336}
        height={252}
      />
      <h3 className="slick_slide__title">
        Sei un insegnante?< br />
        Prestiti agevolati per dipendenti pubblici e statali
      </h3>
      <p className="slick_slide__description">
        Grazie alle convenzioni con istituzioni ed enti pubblici, 
        richiedere un prestito agevolato per dipendenti pubblici e statali offre solo tanti vantaggi: 
        esito in 1 ora, zero spese accessorie, rate personalizzate.
      </p>
      <DefaultButton
        title="Scopri di più"
        url="/prestiti-agevolati-dipendenti-pubblici/"
        className="mt-5 btn-outline-dark"
      />
    </div>
  </SliderBlock>
);
export default SliderTwo;
