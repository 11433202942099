import React, { useState } from "react";
import Vantaggi from "../sections/Vantaggi";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import SliderOne from "../sections/SliderOne";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderTwo from "../sections/SliderTwo";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import BlogSection from "../sections/BlogSection";
import BlogPosts from "../sections/BlogPosts";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import SliderHeader from "../sections/SliderHeader";
import ReviewsSlider from "../sections/ReviewsSlider";
import { Link } from "gatsby";
import Campioni from "../sections/Campioni";
import MutuiCasa from "../sections/MutuiCasa";
import DefaultButton from "../components/buttons/DefaultButton";

const StyledImageFormBlock = styled.div`
  position: relative;

  .overlay {
    position: absolute;
    display: flex;
    top: 50px;
    right: calc(10% + 15px);
    flex-direction: column;
    height: calc(100% - 50px);

    @media(max-width: 1800px) {
      right: 5px;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      left: 0;
      
      display: block;
      top: -10px;
    }

    > * {
      margin: 0 25px;
    }

    &__description {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      position: relative;
      top: 40px;
      font-size: clamp(0.7rem, 0.4000rem + 1.3333vw, 2rem);

      @media(max-width: 768px) {
        font-size: 24px;
      }

      @media (min-width: 1280px) {
        margin-top: 50px;
      }

      .text-yellow {
        color: #e7c700;
        font-weight: 600;
        font-size: clamp(1rem, 0.7231rem + 1.2308vw, 2.2rem);

        @media(max-width: 768px) {
          font-size: 22px;
        }
      }

      .text-gray {
        color: #6d767b;
        @media(max-width: 768px) {
          font-size: 22px;
        }
      }

      .text-black {
        @media(max-width: 768px) {
          font-size: 20px;
        }
      }

      .taeg {
        @media(max-width: 768px) {
          font-size: 16px;
        }
      }

      .text-dark-blue {
        color: #527191;
        @media(max-width: 768px) {
          font-size: 40px;
        }
      }
    }

    form {
      min-width: 500px;
      z-index: 10;
    }
    
    .disclaimer {
      line-height: 12px;
      small {
        font-size: 9px;
      }
    }
  }
`;

const IndexPage = () => {

  const bannerDescriptions = [
    {
      line1: 'I tuoi sogni non aspettano',
      line2: 'Risparmia con il',
      line3: 'Prestito Super Veloce',
      taeg: '5,75%',
      eur: '27.000',
      months: '120',
      installment: '295',
      disclaimer: "* NOTE DI TRASPARENZA PER L'ESEMPIO PRESTITI AGEVOLATI <br />RISERVATI AI DIPENDENTI PUBBLICI E STATALI DI € 27.000"
    },
    {
      line1: 'Se lo Fai Tu, Risparmi di Più!',
      line2: 'Comincia subito con',
      line3: '<span class="fw-bold">Mutuo Casa</span> Green 100% online',
      taeg: '2,96%',
      eur: '100.000',
      months: '',
      installment: '410,89',
      disclaimer: "* NOTE DI TRASPARENZA PER L'ESEMPIO MUTUO CASA GREEN € 100.000"
    },
    {
      line1: 'Se lo Fai Tu, Risparmi di Più!',
      line2: 'Comincia subito con',
      line3: 'il Prestito 100% online',
      taeg: '7,55%',
      eur: '20.000',
      months: '120',
      installment: '235',
      disclaimer: "* NOTE DI TRASPARENZA PER L'ESEMPIO PRESTITI AGEVOLATI RISERVATI AI PENSIONATI DI € 20.000",
    }
  ];

  const [bannerDescriptionNumber, setBannerDescriptionNumber] = useState(0);

  const afterChangeHandler = index => {
      setBannerDescriptionNumber(index)
  }

  const animateCSS = (element, animation, prefix = 'animate__') =>

  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  });


  const beforeChangeHandler = (oldIndex, newIndex) => {
      setBannerDescriptionNumber(newIndex)
      animateCSS('.overlay__description', 'fadeIn')
  }

  return (
    <Layout helmetTitle="Cessione del quinto, prestiti e finanziamenti 100% Online" helmetDescription="Prestiti, cessione del quinto, finanziamenti e mutui. Soluzioni semplici, convenienti e sicure. 100% online. Richiedi subito un preventivo: esito in 1 ora.">
      <StyledImageFormBlock>
        <SliderHeader beforeChangeHandler={beforeChangeHandler} afterChangeHandler={afterChangeHandler} />
        <div className="overlay ">
          <div className="overlay__description display-5 text-center">
            <div className="text-yellow" dangerouslySetInnerHTML={{__html: bannerDescriptions[bannerDescriptionNumber].line1}}></div>
            <div className="text-gray" dangerouslySetInnerHTML={{__html: bannerDescriptions[bannerDescriptionNumber].line2}}></div>
            <div className="text-gray" dangerouslySetInnerHTML={{__html: bannerDescriptions[bannerDescriptionNumber].line3}}></div>
            {
              bannerDescriptions[bannerDescriptionNumber].taeg && 
              <div className="mt-3"><span className="taeg">TAEG</span> <span className="text-dark-blue display-4 fw-bold">
                {bannerDescriptions[bannerDescriptionNumber].taeg}<a href="#disclaimer-prestiti" className="text-decoration-none text-dark-blue">*</a></span> <br />
                  
              </div>
            }

                <div className="text-black">{bannerDescriptions[bannerDescriptionNumber].eur}€ {bannerDescriptions[bannerDescriptionNumber].months ? 'in rate' : 'con rata'} {bannerDescriptions[bannerDescriptionNumber].months} 
                    <span className="fs-4"> da {bannerDescriptions[bannerDescriptionNumber].installment}€*</span>
                </div>
                <div className="disclaimer text-secondary text-uppercase">
                  <small dangerouslySetInnerHTML={{__html: bannerDescriptions[bannerDescriptionNumber].disclaimer}}>
                    {/* {bannerDescriptions[bannerDescriptionNumber].disclaimer} */}
                  </small>
                </div>
                <div>
                  <Link to="/trasparenza/" className="text-secondary fs-6 text-decoration-none"><small>* <span className="text-decoration-underline">Leggi le note di trasparenza</span></small></Link>
                </div>
                {bannerDescriptionNumber === 1 &&
                  <DefaultButton
                    title="Calcola la tua rata"
                    url="/mutui-casa/"
                    className="mt-5 btn-outline-dark border-0"
                    bgColor="#DEC85C"
                  />
                }
          </div>

          <form
            id="form-multi-step"
            action={process.env.GATSBY_FORM_ACTION}
            method="POST"
            className="form-multi-step d-none d-lg-block"
          ></form>
        </div>
      </StyledImageFormBlock>
      <Vantaggi />
      <CessioneFinanziamenti h1 />
      <SliderOne />
      <MutuiCasa />
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-2"
      />
      <Campioni />
      <DipendentePensionatoPrivato />
      <SliderTwo />
      <MetodoPiuVeloce />
      <ReviewsSlider />
      <BlogSection />
      <BlogPosts />
    </Layout>
  );
};

export default IndexPage;
