import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { v4 as uuidv4 } from "uuid";

const Review = (props) => (
    <div className="slick_slide__review text-center">
      <div className="slick_slide__rating mb-4">
        {[...Array(+props.rating || 5)].map((e, i) => (
          <StaticImage
            src="../assets/images/ic_star_rate.png"
            className="d-inline-block "
            alt="Star"
            placeholder="blurred"
            layout="fixed"
            width={28}
            height={28}
            objectFit="contain"
            key={uuidv4()}
          />
        ))}
        <div className="text-secondary">{props.date}</div>
      </div>
      {props.review}
      <div className="slick_slide__author text-uppercase mt-4 fw-bold">
        <a
          href={props.author_link}
          rel="author noreferrer"
          target="_blank"
        >
          {props.author_name}
        </a>
      </div>
    </div>
);

export default Review;
