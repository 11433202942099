import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import DefaultButton from '../components/buttons/DefaultButton'
import styled from 'styled-components'

const MutuiCasa = () => {
    return (
        <StyledMutuiCasa className='container mx-auto'>
            <h2 className='text-center my-5 text-uppercase'>Mutui Casa</h2>
            <div className='row align-items-center justify-content-center g-5'>
                <div className='col-12 col-md-6'>
                    <StaticImage src='../assets/images/calcola-rata-mutuo-home.png' alt='Mutui Casa' placeholder='blurred' layout='fullWidth' />
                </div>
                <div className='col-12 col-md-6 py-3'>
                    <h3 className='text-center text-md-start mb-4 fw-bold fs-3'>Calcola online la rata del tuo mutuo!</h3>
                    <p className='text-center text-md-start my-3 text-blue fw-bold'>
                        Realizza il tuo progetto più importante con il Mutuo PrestitiFaidaTe.
                        Ora anche Online.
                    </p>
                    <p className='text-center text-md-start my-3'>
                        PrestitiFaidaTe ti accompagna nella scelta del mutuo, proponendoti diverse tipologie 
                        di mutuo e aiutandoti a trovare una soluzione di finanziamento in linea 
                        con le tue necessità e quelle della tua famiglia.
                    </p>
                    <p className='text-center text-md-start my-3'>
                        Inserisci la finalità del finanziamento, il valore dell'immobile, l'importo richiesto e i tuoi dati personali 
                        per calcolare l'importo mensile della rata. Puoi modificare e confrontare le rate su durate e importi differenti.
                    </p>
                </div>
            </div>
            <div className='text-center mb-5'>
                <DefaultButton 
                    title="calcola il tuo mutuo" 
                    className="my-5 btn-outline-dark border-0"
                    url="/mutui-casa/"
                    bgColor="#f1f1f1"
                />
            </div>
        </StyledMutuiCasa>
    )
}

const StyledMutuiCasa = styled.div`
    .text-blue {
        color: #34495E;
    }
`

export default MutuiCasa
