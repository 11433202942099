import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CogTitleItems from "../components/CogTitleItems";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";

const StyledCogTitleItems = styled(CogTitleItems)`
  z-index: -1;
  .items {
    &__block {
      padding: 0 2em;
      max-width: 100vw;
    }

    &__title {
      color: #ecc10e;
      padding: 1em 0;
    }
  }
`;

const StyledBlogPosts = styled.div`
  position: relative;
  bottom: 60px;
`;

const BlogPosts = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allWpPost(sort: { fields: date, order: DESC }) {
          nodes {
            title
            excerpt
            content
            slug
            categories {
              nodes {
                slug
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      quality: 75
                      layout: FIXED
                      width: 400
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <StyledBlogPosts>
        <StyledCogTitleItems
          bgColor="#f9f9f9"
          simple
          ctaTitle="VAI AL BLOG"
          url="/blog/"
          id="section-blog-post"
        >
          <div className="items">
            {data.allWpPost.nodes.slice(0, 3).map((node) => (
              <div className="items__block" key={uuidv4()}>
                {node.featuredImage && (
                  <Link to={`/blog/${node.categories.nodes[0].slug}/${node.slug}/`} aria-label={node.title}>
                    <GatsbyImage
                      image={getImage(node.featuredImage.node.localFile)}
                      className="mb-5"
                      alt="Thumbnail"
                    />
                  </Link>
                )}
                <h3 className="pt-4 fw-bolder items__title">
                  <Link
                    to={`/blog/${node.categories.nodes[0].slug}/${node.slug}/`}
                    className="pt-4 fw-bolder items__title text-decoration-none"
                  >
                    {node.title}
                  </Link>
                </h3>
                <div
                  className="items__description"
                  dangerouslySetInnerHTML={{
                    __html:
                      node.excerpt.split('<a class="more-link"')[0] + "</p>",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </StyledCogTitleItems>
      </StyledBlogPosts>
    )}
  />
);

export default BlogPosts;
