import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import CogTitleItems from "../components/CogTitleItems";
import MobileVantaggiSlider from "./MobileVantaggiSlider";

const VantaggiItems = () => (
  <>
    <div className="items__block">
      <StaticImage
        src="../assets/images/vantaggi-100-online.png"
        className="img-fluid mx-auto"
        alt="100% online"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        100% online
      </p>
    </div>
    <div className="items__block">
      <StaticImage
        src="../assets/images/vantaggi-zero-costi-di-gestione.png"
        className="img-fluid mx-auto"
        alt="Zero costi di gestione"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        zero costi di gestione
      </p>
    </div>
    <div className="items__block">
      <StaticImage
        src="../assets/images/vantaggi-1-ora.png"
        className="img-fluid mx-auto"
        alt="Esito in 1 ora"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        esito in 1 ora
      </p>
    </div>
    <div className="items__block">
      <StaticImage
        src="../assets/images/testatina-vantaggi.png"
        className="img-fluid mx-auto"
        alt="Tasso agevolato"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        tasso agevolato
      </p>
    </div>
    <div className="items__block">
      <StaticImage
        src="../assets/images/vantaggi-privacy.png"
        className="img-fluid mx-auto"
        alt="Privacy"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        privacy
      </p>
    </div>
    <div className="items__block">
      <StaticImage
        src="../assets/images/vantaggi-soddisfatti.png"
        className="img-fluid mx-auto"
        alt="100% clienti soddisfatti"
        placeholder="blurred"
        layout="fixed"
        width={140}
        height={140}
        quality={90}
        loading="lazy"
      />
      <p className="pt-4 text-uppercase text-center fw-bolder items__title">
        100% clienti soddisfatti
      </p>
    </div>
  </>
);

const StyledCogTitleItems = styled(CogTitleItems)`
  .items {
    &__title {
      max-width: 150px;
    }
  }
`;

const Vantaggi = () => (
  <StyledCogTitleItems
    title="I vantaggi di Prestiti Fai da Te"
    ctaTitle="Scopri di più"
    url="/vantaggi/"
    description="Entra anche tu nella nostra famiglia e scopri tutti i vantaggi."
    bgColor="#f1f1f1"
    className="mt-2 pb-5"
    id='section-vantaggi'
  >
    <div className="items d-none d-md-flex">
      <VantaggiItems />
    </div>
    <MobileVantaggiSlider className="d-md-none">
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-100-online.png"
          className="img-fluid mx-auto"
          alt="100% online"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          100% online
        </p>
      </div>
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-zero-costi-di-gestione.png"
          className="img-fluid mx-auto"
          alt="Zero costi di gestione"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          zero costi di gestione
        </p>
      </div>
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-1-ora.png"
          className="img-fluid mx-auto"
          alt="Esito in 1 ora"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          esito in 1 ora
        </p>
      </div>
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-taeg.png"
          className="img-fluid mx-auto"
          alt="Tasso al 2,98%"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          tasso al 2,98%
        </p>
      </div>
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-privacy.png"
          className="img-fluid mx-auto"
          alt="Privacy"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          privacy
        </p>
      </div>
      <div className="items__block">
        <StaticImage
          src="../assets/images/vantaggi-soddisfatti.png"
          className="img-fluid mx-auto"
          alt="100% clienti soddisfatti"
          placeholder="blurred"
          layout="fixed"
          width={140}
          height={140}
          quality={90}
          loading="lazy"
        />
        <p className="pt-4 text-uppercase text-center fw-bolder items__title">
          100% clienti soddisfatti
        </p>
      </div>
    </MobileVantaggiSlider>
  </StyledCogTitleItems>
);

export default Vantaggi;
