import React from "react";
import Slider from "react-slick";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

export const autoplaySpeed = 5000

const settings = {
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: autoplaySpeed,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  swipe: false,
  pauseOnHover: false,
  pauseOnDotsHover: false
};

const SliderHeader = (props) => {
  const {
    sliderDesktopOne,
    sliderMobileOne,
    sliderDesktopTwo,
    sliderMobileTwo,
    sliderDesktopThree,
    sliderMobileThree,
    sliderTabletOne,
    sliderTabletTwo,
    sliderTabletThree,
  } = useStaticQuery(
    graphql`
      query {
        sliderDesktopThree: file(relativePath: { eq: "testata3.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderMobileThree: file(relativePath: { eq: "testata3-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderTabletThree: file(relativePath: { eq: "testata3-tablet.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderDesktopTwo: file(relativePath: { eq: "testata2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderMobileTwo: file(relativePath: { eq: "testata2-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderTabletTwo: file(relativePath: { eq: "testata2-tablet.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderDesktopOne: file(relativePath: { eq: "testata1.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderMobileOne: file(relativePath: { eq: "testata1-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }

        sliderTabletOne: file(relativePath: { eq: "testata1-tablet.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FIXED
              quality: 75
            )
          }
        }
      }
    `
  );

  const sliderOneImage = withArtDirection(getImage(sliderDesktopOne), [
    {
      media: "(max-width: 576px)",
      image: getImage(sliderMobileOne),
    },
    {
      media: "(max-width: 992px)",
      image: getImage(sliderTabletOne),
    }
  ]);

  const sliderTwoImage = withArtDirection(getImage(sliderDesktopTwo), [
    {
      media: "(max-width: 576px)",
      image: getImage(sliderMobileTwo),
    },
    {
      media: "(max-width: 992px)",
      image: getImage(sliderTabletTwo),
    }
  ]);

  const sliderThreeImage = withArtDirection(getImage(sliderDesktopThree), [
    {
      media: "(max-width: 576px)",
      image: getImage(sliderMobileThree),
    },
    {
      media: "(max-width: 992px)",
      image: getImage(sliderTabletThree),
    }
  ]);

  return (
    <StyledSlider {...settings} beforeChange={(oldIndex, newIndex) => props.beforeChangeHandler(oldIndex, newIndex)} className="slider">
      <GatsbyImage image={sliderThreeImage} alt="Slider" loading="lazy" />
      <GatsbyImage image={sliderTwoImage} alt="Slider" loading="lazy" />
      <GatsbyImage image={sliderOneImage} alt="Slider" loading="lazy" />
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 2%;
    z-index: 8;
  }
  .slick-dots li button::before {
    font-size: 8px;
  }
`

export default SliderHeader;
