import React from "react";
import SliderBlock from "../components/SliderBlock";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import Review from "../components/Review";

const NextArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-next.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const PrevArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-prev.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const settings = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        dots: false,
        arrows: false,
      },
    },
  ],
};

const StyledBackgroundImage = styled(BackgroundImage)`
  /* background-size: contain; */
`;

const StyledSliderBlock = styled(SliderBlock)`
  max-width: 400px;
  margin: 0 auto;

  .title {
    &--bold {
      font-weight: 900;
    }
    &--yellow {
      color: #e7c700;
    }
  }

  .slick_slide {
    &__author a {
      color: black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ReviewsSlider = (props) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "bolle-senza-centrale.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <StyledBackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
    >
      <StyledSliderBlock
        settings={settings}
        title="<div class='title--bold'>la parola</div> di chi ha già scelto <span class='title--yellow'>#</span>PRESTITI<span class='title--yellow'>FAI</span>DA<span class='title--yellow'>TE</span>"
        bgColor="transparent"
        className="mt-5"
      >
        <Review
          date="23 Giugno 2021"
          review="Ho richiesto un prestito e mi hanno fatto il preventivo in poco
          tempo, soddisfatto!"
          author_link="https://www.google.com/maps/contrib/113903950480608995147/reviews?_ga=2.217234591.805713225.1628238717-97119206.1592811145"
          author_name="Alessandro Zucchetto"
        />
        <Review
          date="25 Giugno 2021"
          review="Operatori gentili e preparati, ben fatto!"
          author_link="https://www.google.com/maps/contrib/103979032768125869728/reviews?_ga=2.184851872.805713225.1628238717-97119206.1592811145"
          author_name="Diego Di Simone"
        />
        <Review
          date="19 Giugno 2021"
          review="ottimo prestito grazie mille gentili e cortesi personale fantastico"
          author_link="https://www.google.com/maps/contrib/105746181684610248310/reviews?_ga=2.184851872.805713225.1628238717-97119206.1592811145"
          author_name="Gaia Falasca"
        />
        <Review
          date="14 Giugno 2021"
          review="Mi hanno aiutato molto, grazie mille del prestito, grazie mille fantastici😍"
          author_link="https://www.google.com/maps/contrib/106317383784813132713/reviews?_ga=2.184851872.805713225.1628238717-97119206.1592811145"
          author_name="Mabel Omowumi"
        />
        <Review
          date="14 Giugno 2021"
          review="Miglior prestito mai ricevuto, fantastici,5 stelle tutte meritatissime!!!!"
          author_link="https://www.google.com/maps/contrib/112722179986725111792/reviews?_ga=2.175984939.805713225.1628238717-97119206.1592811145"
          author_name="Vittoria Omokha"
        />
        <Review
          date="12 Giugno 2021"
          review="Ho fatto un prestito recentemente e devo dire che offrono un ottimo servizio, è affidabile e senza troppe carte in cui perdersi e quindi l’ho anche fatto più serenamente. Molto cordiali e pazienti. Ottimo davvero."
          author_link="https://www.google.com/maps/contrib/107666995924844199236/reviews?_ga=2.175984939.805713225.1628238717-97119206.1592811145"
          author_name="kyuu tetsurou"
        />
        <Review
          date="9 Giugno 2021"
          review="Ho ottenuto un finanziamento con un buon tasso di interesse,professionalità e cortesia"
          author_link="https://www.google.com/maps/contrib/113241304110094559987/reviews?_ga=2.175984939.805713225.1628238717-97119206.1592811145"
          author_name="Salvo La Giglia"
        />
        <Review
          date="3 Giugno 2021"
          review="Mi hanno fornito un prestito senza troppe scartoffie e con facilitazioni di pagamento, il personale mi ha trattato meravigliosamente, li consiglio"
          author_link="https://www.google.com/maps/contrib/113976614470563989809/reviews?_ga=2.210466076.805713225.1628238717-97119206.1592811145"
          author_name="Ronald Larez"
        />
        <Review
          date="3 Maggio 2021"
          review="Il prestito è stato il migliore che ho trovato online"
          author_link="https://www.google.com/maps/contrib/104433144130559269993/reviews?_ga=2.210466076.805713225.1628238717-97119206.1592811145"
          author_name="Filippo Basile"
        />
        <Review
          date="24 Maggio 2021"
          review="Grazie per quello che avete fatto per noi..."
          author_link="https://www.google.com/maps/contrib/104773438245970430411/reviews?_ga=2.210466076.805713225.1628238717-97119206.1592811145"
          author_name="Chiara Caló"
        />
        <Review
          date="30 Aprile 2021"
          review="Volevo un piccolo mutuo per la casa e quello che mi offrivano le banche era pazzesco Mi sono informato e ho trovato questa campagnia dove mi hanno guidato facilmente e comodamente e hanno ottenuto condizioni fantastiche."
          author_link="https://www.google.com/maps/contrib/109906394313570828641/reviews?_ga=2.210466076.805713225.1628238717-97119206.1592811145"
          author_name="Alonzo Bonucci"
        />
        <Review
          date="24 Aprile 2021"
          review="Ho fatto quasi tutto al telefono e tramite operatore. Tutto ok..."
          author_link="https://www.google.com/maps/contrib/112364992735948502720/reviews?_ga=2.214668830.805713225.1628238717-97119206.1592811145"
          author_name="Alessandro Ricci"
        />
      </StyledSliderBlock>
    </StyledBackgroundImage>
  );
};

export default ReviewsSlider;
