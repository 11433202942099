import React from "react";
import styled from "styled-components";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";

const StyledBlogSection = styled.div`
  .blog-section {
    display: flex;
    justify-content: space-between;
    margin: 100px 0;
  }

  .content {
    max-width: 450px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;

    &__title {
      font-weight: 900;
    }

    &__subtitle {
      color: #666666;
    }
  }

  .container-mobile {
    min-height: 720px;
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: cover;
`;

const BlogSection = () => {
  const { blogMobileBackgroundImage } = useStaticQuery(
    graphql`
      query {
        blogMobileBackgroundImage: file(
          relativePath: { eq: "blog-section-mobile.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  );

  const blogMobileImage = getImage(blogMobileBackgroundImage);
  const bgBlogMobileImage = convertToBgImage(blogMobileImage);

  return (
    <StyledBlogSection id="section-blog">
      <div className="blog-section container-desktop d-none d-lg-flex">
        <StaticImage
          src="../assets/images/blog-section-left.png"
          className="img-fluid"
          alt="Image"
          placeholder="blurred"
          layout="constrained"
          width={1458}
          height={534}
          objectFit="contain"
        />
        <div className="content">
          <StaticImage
            src="../assets/images/blog-section-center.png"
            className="mx-auto"
            alt="Image"
            placeholder="blurred"
            layout="constrained"
            width={97}
            height={131}
            objectFit="contain"
          />
          <h2 className="content__title pb-2">A Tutto Prestiti</h2>
          <p className="content__subtitle">di Prestiti Fai da Te</p>
          <p className="content__description">
            Nel blog di PrestitiFaidaTe.it trovi tanti articoli per saperne di
            più su Cessione del quinto, prestiti, finanzimaenti e mutui casa.
            Segui i consigli dei nostri esperti!
          </p>
        </div>
        <StaticImage
          src="../assets/images/blog-section-right.png"
          className="img-fluid"
          alt="Image"
          placeholder="blurred"
          layout="constrained"
          width={567}
          height={558}
          objectFit="contain"
        />
      </div>
      <div className="blog-section container-mobile d-flex d-lg-none">
        <StyledBackgroundImage
          Tag="div"
          {...bgBlogMobileImage}
          preserveStackingContext
          className="mx-auto"
        >
          <div className="content p-5">
            <h2 className="content__title pb-2">A Tutto Prestiti</h2>
            <p className="content__subtitle">di Prestiti Fai da Te</p>
            <p className="content__description">
              Nel blog di PrestitiFaidaTe.it trovi tanti articoli per saperne di
              più su Cessione del quinto, prestiti, finanzimaenti e mutui casa.
              Segui i consigli dei nostri esperti!
            </p>
          </div>
        </StyledBackgroundImage>
      </div>
    </StyledBlogSection>
  );
};

export default BlogSection;
